<template>
<div class="main-content">
  <nav class="breadcrumb">
    <ul class="breadcrumb__nav">
      <!-- <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></li> -->
      <li class="breadcrumb__nav-item">{{$t('adminlist.breadcrumb')}}</li>
    </ul>
  </nav>
  <header class="titles-header">
    <div class="titles-header__side">
      <h2 class="titles-header__title">{{$t('adminlist.title')}}</h2>
      <p class="titles-header__subtitle subtitle">{{$t('adminlist.subtitle')}} <span class="meaning">+MEANING</span></p>
    </div>
  </header>
  <section class="main-section">
    <router-link :to="{ name: 'AdminsInvite' }" class="btn btn--primary btn--circle btn--fixed"><img src="@/assets/images/icons/ico-add--white.svg"></router-link>
    <div class="grid grid-3">
      <div v-for="user in users" :key="user.id" class="card">
        <div class="card__top">
          <div class="card__icon">{{$utils.extractInitials(user)}}</div>
          <div class="card__titles">
            <h3>{{user.first_name}} {{user.last_name}}</h3>
          </div>
        </div>
        <div class="card__middle">
          <div class="card__info">
            <div class="card__info-item">
              <img class="card__item-icon" src="@/assets/images/icons/ico-email--yellow.svg">
              <p class="card__item-data">{{user.email}}</p> 
            </div>
          </div>
        </div>
        <div class="card__actions">
          <div class="dropdown">
            <button class="btn btn--primary btn--block">{{$t('adminlist.btnoptions')}}</button>
            <div class="dropdown__options">
              <router-link :to="{ name: 'AdminsEdit', params: { userId: user.id } }">{{$t('adminlist.linkedit')}}</router-link>
              <a class="danger" v-if="user.id !== $store.state.user.id && user.email !== indelibleAdminEmail" href="" @click.prevent="deleteUser(user.id)">{{$t('adminlist.delete')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="main-section" v-if="invites.length">
    <h3 class="main-section__title">{{$t('adminlist.pendinginvitation')}}</h3>
    <div class="list">
      <div v-for="invite in invites" :key="invite.id" class="card">
        <div class="card__top">
          <p>{{invite.email}}</p>
        </div>
        <div class="card__middle"></div>
        <div class="card__actions">
          <div class="dropdown">
            <button class="btn btn--primary btn--outline btn--xs">{{$t('adminlist.btnoptions')}}</button>
            <div class="dropdown__options">
              <a href="" @click.prevent="resendInvite(invite)">{{$t('adminlist.resendinvitation')}}</a>
              <a class="danger" href="" @click.prevent="showConfirmDeleteInviteModal = invite; deleteInvite(invite.id)">{{$t('adminlist.deleteinvitation')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  name: 'AdminsList',
  data () {
    return {
      users: [],
      invites: [],
      showConfirmDeleteUserModal: false,
      showConfirmDeleteInviteModal: false
    }
  },
  methods: {
    deleteUser (id) {
      if (confirm(this.$t('adminlist.deleteuser'))) {
        this.$axios.delete(`users/${id}`).then(res => {
          this.showConfirmDeleteUserModal = false
          if (!res.data.user) {
            return alert(this.$t('adminlist.nodelete'))
          }
          this.users = this.users.filter(user => user.id !== id)
        })
      } else {
        this.showConfirmDeleteUserModal = false
      }
    },
    deleteInvite (id) {
      if(confirm(this.$t('adminlist.confirmmsg'))) {
        this.$axios.delete(`invites/${id}`).then(() => {
          this.invites = this.invites.filter(invite => invite.id !== id)
          this.showConfirmDeleteInviteModal = false
        })
      } else {
        this.showConfirmDeleteInviteModal = false
      }
    },
    resendInvite (invite) {
      this.$axios.post('invites/admin', { email: invite.email }).then(() => {
        alert(this.$t('adminlist.successmsg'))
      })
    }
  },
  computed: {
    indelibleAdminEmail () {
      return process.env.VUE_APP_INDELIBLE_ADMIN
    }
  },
  async created () {
    let res = await this.$axios.get('users/admins')
    this.users = res.data.users

    res = await this.$axios.get('invites/admins')
    this.invites = res.data.invites
  }
}
</script>
